import prj1 from "../images/project/1.jpg";
import prj2 from "../images/project/2.jpg";
import prj3 from "../images/project/3.jpg";
import prj4 from "../images/project/4.jpg";
import prj5 from "../images/project/5.jpg";
import prj6 from "../images/project/6.jpg";

import prwj1 from "../images/project/7.jpg";
import prwj2 from "../images/project/11.jpg";
import prwj3 from "../images/project/9.jpg";
import prwj4 from "../images/project/10.jpg";
import prwj5 from "../images/project/8.jpg";

import propj1 from "../images/project/12.jpg";
import propj2 from "../images/project/13.jpg";
import propj3 from "../images/project/14.jpg";
import propj4 from "../images/project/15.jpg";
import propj5 from "../images/project/16.jpg";

import prontj1 from "../images/project/17.jpg";
import prontj2 from "../images/project/19.jpg";
import prontj3 from "../images/project/20.jpg";
import prontj4 from "../images/project/21.jpg";
import prontj5 from "../images/project/22.jpg";
import prontj6 from "../images/project/18.jpg";

import primg1 from "../images/project-single/2.jpg";
import primg2 from "../images/project-single/3.jpg";

import prwimg1 from "../images/project-single/4.jpg";
import prwimg2 from "../images/project-single/5.jpg";

import propimg1 from "../images/project-single/6.jpg";
import propimg2 from "../images/project-single/7.jpg";

import pront1 from "../images/project-single/nt1.jpg";
import pront2 from "../images/project-single/nt2.jpg";

const Projects = [
  {
    id: "1",
    projectImg: prj1,
    title: "School In Africa",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "2",
    projectImg: prj2,
    title: "School In Ghana",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "3",
    projectImg: prj3,
    title: "School In Nigeria",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "4",
    projectImg: prj4,
    title: "School In Uganda",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "5",
    projectImg: prj5,
    title: "School In India",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "6",
    projectImg: prj6,
    title: "School In PNG",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: primg1,
    prImg2: primg2,
  },
  {
    id: "7",
    projectImg: prwj1,
    title: "Sundarbans Royel Tiger",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: "8",
    projectImg: prwj2,
    title: "Australian Camel",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: "9",
    projectImg: prwj3,
    title: "African Macaw Bird",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: "10",
    projectImg: prwj4,
    title: "US Deer",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: "11",
    projectImg: prwj5,
    title: "African Elephant",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: prwimg1,
    prImg2: prwimg2,
  },
  {
    id: "12",
    projectImg: propj1,
    title: "Save Ocean",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: "13",
    projectImg: propj2,
    title: "Save Ocean",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: "14",
    projectImg: propj3,
    title: "Save Ocean",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: "15",
    projectImg: propj4,
    title: "Save Ocean",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: "16",
    projectImg: propj5,
    title: "Save Ocean",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: propimg1,
    prImg2: propimg2,
  },
  {
    id: "17",
    projectImg: prontj1,
    title: "Amazon Rainforest",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: "18",
    projectImg: prontj2,
    title: "Amazon Rainforest",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: "19",
    projectImg: prontj3,
    title: "African forest",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: "20",
    projectImg: prontj4,
    title: "African forest plantation",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: "21",
    projectImg: prontj5,
    title: "Shundarbans Forest",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
  {
    id: "22",
    projectImg: prontj6,
    title: "Amazon Rainforest",
    dec: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prImg1: pront1,
    prImg2: pront2,
  },
];

export default Projects;
