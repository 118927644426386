import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";

const Service3 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-features-section-s4 section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <h2>Underwriting events to support non profit organizations</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {Services.slice(11, 14).map((service, sitem) => (
            <div className="col col-xl-4 col-lg-6 col-sm-6 col-12" key={sitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <i
                      style={{ fontSize: 40 }}
                      className={`fa fa-${service.fIcon1} fa-lg`}
                    ></i>
                  </div>
                </div>
                <div className="wpo-features-text">
                  <h2>
                    <Link
                      onClick={ClickHandler}
                      to={`/service-single/${service.id}`}
                    >
                      {service.title}
                    </Link>
                  </h2>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service3;
