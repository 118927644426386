import React from "react";
import { Link } from "react-router-dom";
import Clients from "./client";

import hero1 from "../../images/slider/right-image-new-1.jpg";
import hero2 from "../../images/slider/s.png";
import hero3 from "../../images/slider/right-image-new-2.jpg";

const Hero = () => {
  return (
    <section className="wpo-hero-section-1">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col col-xs-6 col-lg-6">
            <div className="wpo-hero-section-text">
              <div className="wpo-hero-title-top">
                <span>
                  Basic Rights, Infinite Possibilities: Education Matters!
                </span>
              </div>
              <div className="wpo-hero-title">
                <h2>Impact &bull; Passion &bull; Fairness &bull; Focus</h2>
              </div>
              <div className="wpo-hero-subtitle">
                <p>
                  The BTS Foundation exists to support equality in education.
                  This especially includes opportunities for children who have
                  fallen behind due to life circumstances beyond their control.
                </p>
              </div>
              <div className="btns">
                <Link to="/#foundation-projects" className="btn theme-btn-s2">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-vec">
              <div className="right-items-wrap">
                <div className="right-item">
                  <div className="r-img">
                    <img src={hero1} alt="" />
                  </div>
                </div>
                <div className="right-item">
                  <div className="wpo-total-project">
                    <div className="wpo-total-project-wrap">
                      <div className="wpo-total-project-icon">
                        <i className="fi flaticon-salary"></i>
                      </div>
                      <div className="wpo-total-project-text">
                        <h3>10+</h3>
                        <p>Total Projects</p>
                      </div>
                    </div>
                    <div className="project-shape">
                      <img src={hero2} alt="" />
                    </div>
                  </div>
                  <div className="r-img">
                    <img src={hero3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
