import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.svg";
import ProjectSection from "../../components/ProjectSection";

const ProjectPage = () => {
  return (
    <Fragment>
      <Navbar2 Logo={Logo} />
      <PageTitle
        pageTitle={"Our Projects"}
        pagesub={"Learn More About Projects"}
      />
      <ProjectSection pbClass={"pb-0"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectPage;
