import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/service";

const Service5 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      id="foundation-projects"
      className="wpo-features-section-s6 section-padding"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="wpo-section-title">
              <span>What We Do</span>
              <h2>Our Foundation Projects</h2>
              <p>
                The BTS Foundation exists to support equality in education. This
                especially includes opportunities for children who have fallen
                behind due to life circumstances beyond their control. These
                young people may have been placed in foster care to escape
                abusive situations, forced into a refugee life, suffering from
                mental and emotional health issues, or have other impediments
                that delay their educational progress.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {Services.slice(4, 11).map((service, sitem) => (
            <div className="col col-xl-4 col-lg-4 col-sm-6 col-12" key={sitem}>
              <div className="wpo-features-item">
                <div className="wpo-features-icon">
                  <div className="icon">
                    <i
                      style={{ fontSize: 40 }}
                      className={`fa fa-${service.fIcon1} fa-lg`}
                    ></i>
                    {/* <i className={`fi  ${service.fIcon1}`}></i> */}
                  </div>
                </div>
                <div className="wpo-features-text">
                  <h2>
                    <Link
                      onClick={ClickHandler}
                      //to={`/service-single/${service.id}`}
                    >
                      {service.title}
                    </Link>
                  </h2>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service5;
