import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo_white.svg";
import Projects from "../../api/projects";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p>
                  The Foundation is committed to helping change the trajectory
                  of lives that have been obstructed by circumstances or
                  illnesses that have affected educational opportunities.
                </p>
                <ul>
                  <li>
                    <Link to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Foundation</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/projects">
                      Projects
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/events">
                      Events
                    </Link>
                  </li> */}
                  <li>
                    {/*<Link onClick={ClickHandler} to="/sponsors">
                      Sponsors
  </Link> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <p>Please feel free to contact us with any inquiries:</p>
                  <ul>
                    <li>
                      <i className="fi flaticon-mail"></i>shagrithayafoundation@gmail.com 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                &copy; 2023 BTS Foundation. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
