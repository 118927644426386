import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Homepage from "../HomePage";
import EventPage from "../EventPage";
import ProjectPage from "../ProjectPage";
import CausePage from "../CausePage";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} /> 
          <Route path="/projects" component={ProjectPage} />
          {/*<Route path="/events" component={EventPage} /> */ }
          {/*<Route path="/sponsors" component={CausePage} /> */}
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
