import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import Hero from "../../components/hero";
import Service from "../../components/Service";
import Service3 from "../../components/Service3";

import Service5 from "../../components/Service5";
import About from "../../components/about";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.svg";
import PartnerSection from "../../components/PartnerSection";
import vimg from "../../images/volunteer.png";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar2 Logo={Logo} />
      <Hero />
      <Service Fclass={"wpo-features-section-s2"} vclassClass={"v1"} />
      {/*<About />*/}
      <Service5 />
      <Service3 />
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
