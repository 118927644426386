import himg1 from "../images/service-single/1.jpg";
import himg2 from "../images/service-single/2.jpg";
import himg3 from "../images/service-single/3.jpg";
import cimg from "../images/service-single/4.jpg";
import mimg from "../images/service-single/5.jpg";
import eimg from "../images/service-single/6.jpg";
import seimg from "../images/service-single/7.jpg";
import ptimg from "../images/service-single/8.jpg";
import rhimg from "../images/service-single/9.jpg";

import wimg1 from "../images/service-single/w1.jpg";
import wimg2 from "../images/service-single/w2.jpg";

import ocimg from "../images/service-single/10.jpg";
import ocimg2 from "../images/service-single/11.jpg";
import ocimg3 from "../images/service-single/12.jpg";
import ocimg4 from "../images/service-single/13.jpg";
import ocimg5 from "../images/service-single/14.jpg";

import wpimg1 from "../images/service-single/15.jpg";
import wpimg2 from "../images/service-single/16.jpg";
import wpimg3 from "../images/service-single/17.jpg";

import wpimg4 from "../images/service-single/wp1.jpg";
import wpimg5 from "../images/service-single/wp2.jpg";

import ntimg1 from "../images/service-single/18.jpg";
import ntimg2 from "../images/service-single/20.jpg";
import ntimg3 from "../images/service-single/19.jpg";

import ntimg4 from "../images/service-single/nt1.jpg";
import ntimg5 from "../images/service-single/nt2.jpg";

import picon1 from "../images/icon/1.png";
import picon2 from "../images/icon/2.png";
import picon3 from "../images/icon/3.png";

const Services = [
  {
    id: "1",
    fIcon1: "flaticon-medicine",
    title: "Impact",
    description:
      "The Foundation is committed to helping change the trajectory of lives that have been obstructed by circumstances or illnesses that have affected educational opportunities.",
    simg1: himg1,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "2",
    fIcon1: "flaticon-water-tap",
    title: "Passion",
    description:
      "Created by humanitarians who credit their personal success to the education they received; they now reach out to those in need of supportive education that will allow them to progress through life.",
    simg1: cimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "3",
    fIcon1: "flaticon-vegetable",
    title: "Fairness",
    description:
      "By championing fairness in education, we pave the way for a brighter future, breaking down barriers and opening doors to knowledge, empowerment, and shared success.",
    simg1: mimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "4",
    fIcon1: "flaticon-graduation-cap",
    title: "Focus",
    description:
      "The BTS Foundation passionately advocates for education accessibility, supporting schools capital campaign and mental health programs for university students who have aged out of the foster care system.",
    simg1: eimg,
    simg2: himg2,
    simg3: himg3,
  },
  {
    id: "5",
    fIcon1: "diamond",
    title: "Alcuin Innovation Lab",
    description: "The conception and execution of the Alcuin Innovation Lab involved a meticulous design and implementation process. This initiative aimed to create a cutting-edge space, fostering innovation and experiential learning within the Alcuin school environment.",
    simg1: seimg,
    simg2: wimg1,
    simg3: wimg2,
  },
  {
    id: "6",
    fIcon1: "building",
    title: "Buildings and Infrastructure",
    description:
      "Supporting the development of Arts and Language buildings, alongside the establishment of a high school and associated infrastructure, reflects a strategic and forward-thinking initiative. This multifaceted project aims to enhance the educational landscape by providing state-of-the-art facilities that actively support artistic and linguistic pursuits, fostering a conducive environment for high school education.",
    simg1: ptimg,
    simg2: wimg1,
    simg3: wimg2,
  },
  {
    id: "7",
    fIcon1: "certificate",
    title: "Collin County Community College",
    description: "Sponsoring the endowment of tuition and scholarships signifies a commitment to supporting educational opportunities and fostering academic excellence.",
    simg1: rhimg,
    simg2: wimg1,
    simg3: wimg2,
  },
  {
    id: "8",
    fIcon1: "globe",
    title: "University of North Texas",
    description:
      "The implementation and support of the UNT Mental Health Program, finely tuned for the unique needs of young individuals aging out of foster care, address a cohort facing distinct challenges. Youth aging out of foster care often encounter formidable obstacles in their transition to adulthood. These challenges include limited familial support, financial instability, and the absence of a safety net that exacerbates the already vulnerable position they find themselves in. Recognizing these hurdles, our tailored mental health program strives to provide essential support, acknowledging the nuanced struggles these individuals face as they navigate the complexities of independence.",
    simg1: ocimg,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "9",
    fIcon1: "building",
    title: "Temple Texas, Temple",
    description:
      "The construction of the main Gopuram and the establishment of an endowment for the continuous service to the community, alongside the implementation of solar panels to curtail carbon footprint and reduce operational costs, mark a comprehensive initiative. This endeavor signifies a commitment to architectural and environmental enhancements, as well as ensuring sustained community support through thoughtful financial planning and eco-friendly practices.",
    simg1: ocimg2,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "10",
    fIcon1: "user",
    title: "South Bombay, Kannada association",
    description: "The establishment of an Elderly People's Facility and Community Hall providing a dedicated space that caters to the unique needs of our senior community members. This facility is designed to foster a sense of community, support, and engagement for the elderly, offering a purposeful space for various activities and interactions.",
    simg1: ocimg3,
    simg2: ocimg4,
    simg3: ocimg5,
  },
  {
    id: "11",
    fIcon1: "book",
    title: "Indian Institute of Technology (IITB), India",
    description: "The creation and support of a Library Research Center exemplify a tangible commitment to fostering academic exploration and knowledge acquisition. This initiative is designed to provide a dynamic space where research thrives, facilitating a culture of continuous learning and scholarly engagement.",
    simg1: wpimg1,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "12",
    fIcon1: "child",
    title: "Children Advocacy Services",
    description: "The support of CASA events in Texas chapters advocating for the well-being of children in the foster care system. This backing ensures the success and impact of CASA events, contributing to the overall mission of providing a voice for children in need.",
    simg1: wpimg2,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "13",
    fIcon1: "book",
    title: "Alcuin School Gala",
    description:
      "Support of Alcuin School Gala fundraising events to foster excellence in education. This ongoing support contributes to the success of these events, providing essential resources for the school's programs and initiatives.",
    simg1: wpimg3,
    simg2: wpimg4,
    simg3: wpimg5,
  },
  {
    id: "14",
    fIcon1: "group",
    title: "Feed America",
    description: "Support of Feed America events to assist non-profit organizations dedicated to addressing hunger and food insecurity. This backing actively contributes to the collective effort of ensuring that individuals and communities in need have access to vital resources, fostering a positive impact on their well-being and overall quality of life.",
    simg1: ntimg3,
    simg2: ntimg4,
    simg3: ntimg5,
  },
  {
    id: "15",
    fIcon1: "flaticon-ecology",
    title: "Recyling Process",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ntimg1,
    simg2: ntimg4,
    simg3: ntimg5,
  },
  {
    id: "16",
    fIcon1: "flaticon-eco-light",
    title: "Green Energy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    simg1: ntimg2,
    simg2: ntimg4,
    simg3: ntimg5,
  },
];
export default Services;
